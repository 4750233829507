import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"

function PlayStore() {
  const styles = {
    appStoreContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#f0f2f5",
      padding: "2rem",
    },
    appCard: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "1.5rem",
      margin: "1rem",
      width: "330px",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease",
      cursor: "pointer",
      border: "1px solid #ddd",
      borderRadius: "10px",
    },
    appTitle: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "1rem",
      color: "#333",
      fontFamily: "Arial, sans-serif",
    },
    qrCode: {
      width: "120px",
      height: "120px",
      borderRadius: "10px",
      marginBottom: "1rem",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    qrText: {
      color: "#666",
      fontSize: "0.9rem",
    },
  }

  return (
    <Layout>
      <Header class="main-container fixed w-full z-50 top-0" />

      <div style={styles.appStoreContainer}>
        <div style={styles.appCard}>
          <div className="flex  gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi text-blue-400 mt-0.5 bi-google-play"
              viewBox="0 0 16 16"
            >
              <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27" />
            </svg>

            <h3 style={styles.appTitle}>Customer App</h3>
          </div>
          <img
            style={styles.qrCode}
            src="https://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.manusapp.manusapp&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L"
            alt="qr code"
          />
          <p style={styles.qrText}>Scan the QR code to download</p>
        </div>
        <div style={styles.appCard}>
          <div className="flex  gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi text-blue-400 mt-0.5 bi-google-play"
              viewBox="0 0 16 16"
            >
              <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27" />
            </svg>
            <h3 style={styles.appTitle}>Tradesperson App</h3>
          </div>

          <img
            style={styles.qrCode}
            src="https://api.qrserver.com/v1/create-qr-code/?color=000000&amp;bgcolor=FFFFFF&amp;data=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.manustradespeople.manustradespeople&amp;qzone=1&amp;margin=0&amp;size=400x400&amp;ecc=L"
            alt="qr code"
          />
          <p style={styles.qrText}>Scan the QR code to download</p>
        </div>
      </div>
    </Layout>
  )
}

export default PlayStore
